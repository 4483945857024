.TabView {
  background: #fff;
  text-align: left;
  overflow: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.TabViewTitleContainer {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 2vw 0 0;
}

.TabViewTitle,
.TabViewContent {
  padding: 2vw;
}

.TabViewTitle {
  padding-bottom: 0;
}
