.FundFilterBox {
  padding-bottom: 4px;
}

.FundFilterBox button {
  width: 100%;
  justify-content: left;
  height: 33px;
}

.FundFilterBox .hr {
  box-sizing: border-box;
  margin-bottom: 4px;
}

.FundFilterBox .hr:first-child,
.FundFilterBox .col1 {
  padding-right: 4px;
}
.FundFilterBox .hr:last-child,
.FundFilterBox .col3 {
  padding-left: 4px;
}

.MuiToggleButton-root.filter-button.cta:hover {
  background-color: rgba(196, 16, 160, 0.8);
}

.MuiToggleButton-root.filter-button.cta {
  background-color: rgba(196, 16, 160, 1);
}

.MuiToggleButton-root.filter-button {
  background-color: rgba(40, 87, 128, 1);
  color: white;
  margin: 2px 0;
}

.MuiToggleButton-root.filter-button:hover {
  background-color: rgba(40, 87, 128, 0.8);
}

.MuiToggleButton-root.Mui-selected.filter-button {
  background-color: rgba(127, 169, 174, 1);
  color: white;
}

.MuiToggleButton-root.Mui-selected.filter-button:hover {
  background-color: rgba(127, 169, 174, 0.8);
}
